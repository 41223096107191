import { ApiDto } from '@portals/shared/portal/ApiDto';
import { useMemo } from 'react';
import useSWR from 'swr';

import ApiPage from '../../components/ApiPage';
import { API_EXPLORER_TABS, INTERNAL_CLASSIFICATIONS } from '../../content';

export default function InternalApis() {
  const { data: apis, mutate } = useSWR<ApiDto[]>('/api?type=REST');
  const internalApis = useMemo(() => {
    return apis?.filter((api) =>
      INTERNAL_CLASSIFICATIONS.has(api.classification),
    );
  }, [apis]);

  return (
    <ApiPage
      apiType={API_EXPLORER_TABS.Internal}
      apis={internalApis || null}
      onApiFavouriteChange={(apiId, isFavorite) =>
        mutate((apis) => {
          if (apis) {
            const updatedApis = [...apis];
            const index = updatedApis.findIndex((api) => api.id === apiId);
            updatedApis[index].isFavorite = isFavorite;
            return [...updatedApis];
          }
          return;
        }, false)
      }
      title="Internal APIs"
    />
  );
}
