import Markdown from '@/components/Markdown';

import GuideTableOfContent from './GuideTableOfContent';

import { Page } from '@/pages/api-documentation/styles/Api.Reference.styles';

interface Props {
  guide: string | undefined;
  style?: React.CSSProperties;
}

// TODO: fix the need for custom style handling pre breakouts
const Guide = ({ guide, style }: Props): JSX.Element => {
  return (
    <Page>
      <GuideTableOfContent guide={guide} />
      <div className="content" style={style}>
        {guide && <Markdown>{guide}</Markdown>}
      </div>
    </Page>
  );
};

export default Guide;
