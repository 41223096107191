import DeveloperPortalGuideContent from '@/components/Guide/DeveloperPortalGuideContent';
import HeroPage from '@/components/HeroPage';

export default function OAuth2AuthenticationGuide(): JSX.Element {
  return (
    <HeroPage
      heroIllustration={require('@/illustrations/DNB_Supergraphics_front_page.avif?url')}
      noContainer
      noTopMargin
      title="OAuth2 Authentication"
    >
      <DeveloperPortalGuideContent contentSlug="oauth2-authentication-internal" />
    </HeroPage>
  );
}
