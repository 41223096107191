import { SWRConfiguration } from 'swr';

import { ApiError } from '@/request';

export const swrExpect404: SWRConfiguration = {
  onError: (error: unknown) => {
    if (ApiError.isApiError(error) && error.status === 404) return;
    throw error;
  },
};
