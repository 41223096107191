import { Checkbox, Flex, P } from '@dnb/eufemia';
import { EventClassification } from '@portals/shared/portal/EventDto';
import { Dispatch, SetStateAction } from 'react';

import { removeStringFromArray } from '@/utils/arrayUtils';

interface EventFilterProps {
  setActiveFilters: Dispatch<SetStateAction<string[]>>;
  activeFilters: string[];
}

export default function EventFilter({
  setActiveFilters,
  activeFilters,
}: EventFilterProps) {
  return (
    <Flex.Vertical>
      <P modifier="medium" space={{ bottom: 'x-small' }}>
        Event classification
      </P>
      <Checkbox
        checked={activeFilters.length === 0}
        label="All"
        onChange={({ checked }) =>
          checked
            ? setActiveFilters([])
            : setActiveFilters(removeStringFromArray(activeFilters, 'all'))
        }
      />
      <Checkbox
        checked={activeFilters.includes(EventClassification.Domain)}
        label="Domain"
        onChange={({ checked }) =>
          checked
            ? setActiveFilters([...activeFilters, EventClassification.Domain])
            : setActiveFilters(
                removeStringFromArray(
                  activeFilters,
                  EventClassification.Domain,
                ),
              )
        }
      />
      <Checkbox
        checked={activeFilters.includes(EventClassification.Experience)}
        label="Experience"
        onChange={({ checked }) =>
          checked
            ? setActiveFilters([
                ...activeFilters,
                EventClassification.Experience,
              ])
            : setActiveFilters(
                removeStringFromArray(
                  activeFilters,
                  EventClassification.Experience,
                ),
              )
        }
      />
      <Checkbox
        checked={activeFilters.includes(EventClassification.System)}
        label="System"
        onChange={({ checked }) =>
          checked
            ? setActiveFilters([...activeFilters, EventClassification.System])
            : setActiveFilters(
                removeStringFromArray(
                  activeFilters,
                  EventClassification.System,
                ),
              )
        }
      />
    </Flex.Vertical>
  );
}
