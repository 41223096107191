/* eslint-disable max-lines */
import {
  Button,
  Dialog,
  FormRow,
  FormSet,
  FormStatus,
  H3,
  H4,
  Link,
  P,
  Radio,
  Space,
  Tooltip,
} from '@dnb/eufemia';
import { shield_medium as ShieldIcon } from '@dnb/eufemia/icons';
import type {
  ApiKeyDto,
  CreateApiKeyRequestDto,
} from '@portals/shared/portal/ApiKeyDto';
import {
  AccessTypes,
  type AppWithEntitlementsDto,
} from '@portals/shared/portal/AppDto';
import type {
  CiamClientDto,
  ClientDto,
} from '@portals/shared/portal/ClientDto';
import { useAsync, useEufemiaForm } from '@portals/shared-frontend/hooks';
import { ApiError } from '@portals/shared-frontend/utils/ApiError';
import { useCallback, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import useSWR from 'swr';
import { string, z } from 'zod';

import { createApiKey, deleteApiKey, deleteClientFromPortal } from '@/api/app';
import Card from '@/components/Card';
import Divider from '@/components/Divider';
import LoadingModal from '@/components/LoadingModal';
import NoData from '@/components/NoData';
import useAuth from '@/hooks/useAuth';
import useFeatureFlags from '@/hooks/useFeatureFlags';

import Apikey from '../ApiKey';
import Client from '../Client';

import style from './index.module.css';

const privilegeGroup =
  ENVIRONMENT_NAME === 'prod'
    ? {
        displayName:
          'BSN0005357 DNB Developer Portal Client MGMT Eligible Operator Access in Production',
        id: 'App_BSN0005357_DNBDeveloperPortal_Client_Mgmt_Eligible_prod_opr',
      }
    : {
        displayName:
          'BSN0005357 DNB Developer Operator Access to Client MGMT Eligible Dev',
        id: 'App_BSN0005357_DNB_Developer_Client_Mgmt_Eligible_dev_operator',
      };

interface CredentialProps {
  app: AppWithEntitlementsDto;
  isAdmin: boolean;
  onApiKeyCreated: (apiKey: ApiKeyDto) => void;
  onApiKeyDeleted: (apiKeyId: string) => void;
  onClientDeleted: (clientId: string) => void;
  onSetError: (error: string) => void;
  error: string | undefined;
}

export const createApiKeyFormSchema = z.object({
  mode: string(),
});

export default function Credential({
  app,
  isAdmin,
  onApiKeyCreated,
  onApiKeyDeleted,
  onClientDeleted,
  onSetError,
  error,
}: CredentialProps) {
  const navigate = useNavigate();
  const { featureFlags, isLoading: flagsLoading } = useFeatureFlags();
  const { roles } = useAuth();
  const {
    data: ciamClients,
    isValidating: ciamClientsLoading,
    mutate: mutateCiamClients,
  } = useSWR<CiamClientDto[]>(
    app.accessType === AccessTypes.O_AUTH_SYSTEM_TO_SYSTEM
      ? `/apps/${app.id}/ciam-clients`
      : null,
    { revalidateOnMount: true },
  );

  useEffect(() => {
    if (app.accessType === AccessTypes.O_AUTH_SYSTEM_TO_SYSTEM) {
      mutateCiamClients();
    }
  }, [app.accessType, app.currentApiAndScopeEntitlements, mutateCiamClients]);

  const onCreateApiKey = useAsync(
    async (data: CreateApiKeyRequestDto) => {
      try {
        if (app) {
          const apiKey = await createApiKey(app.id, data);
          onApiKeyCreated(apiKey);
        }
      } catch (error) {
        if (ApiError.isApiError(error)) {
          onSetError(error.message);
        } else {
          onSetError('Something went wrong creating the API key.');
        }
      }
    },
    [app, onApiKeyCreated, onSetError],
  );

  useEffect(() => {
    if (error) {
      setTimeout(() => {
        onSetError('');
      }, 3500);
    }
  }, [error, onSetError]);

  const onDeleteApiKey = useAsync(
    async (apiKey: ApiKeyDto) => {
      if (app) {
        await deleteApiKey(app.id, apiKey.key);
        onApiKeyDeleted(apiKey.id);
      }
    },
    [app, onApiKeyDeleted],
  );
  const onDeleteClient = (clientId: string) => {
    onClientDeleted(clientId);
  };
  const onDeleteClientFromPortal = useAsync(
    async (client: ClientDto) => {
      if (app) {
        await deleteClientFromPortal(app.id, client.id);
        onClientDeleted(client.id);
      }
    },
    [app, onClientDeleted],
  );

  const liveClients =
    app.accessType === AccessTypes.API_KEYS
      ? app?.apiKeys.filter(({ liveMode }) => liveMode)
      : app?.clients.filter(({ liveMode }) => liveMode);
  const testClients =
    app.accessType === AccessTypes.API_KEYS
      ? app?.apiKeys.filter(({ liveMode }) => !liveMode)
      : app?.clients.filter(({ liveMode }) => !liveMode);

  const hasClientManagementGrp = useMemo(() => {
    return isAdmin && roles.includes(privilegeGroup.id);
  }, [isAdmin, roles]);

  const tipMessage = isAdmin ? (
    hasClientManagementGrp ? undefined : (
      <>
        Request access to &quot;{privilegeGroup.displayName}&quot;{' '}
        <Link
          href="https://dnbprod.service-now.com/resolve?id=sc_cat_item_guide&sys_id=549b0f921bb3949089ab542a2d4bcb09"
          rel="noopener noreferrer"
          target="_blank"
        >
          here
        </Link>
      </>
    )
  ) : (
    'Admin access required'
  );

  return (
    <>
      {(onCreateApiKey.waiting || onDeleteApiKey.waiting || flagsLoading) && (
        <LoadingModal />
      )}

      <H3 bottom="medium">
        <ShieldIcon /> Credentials
      </H3>

      {app.accessType === AccessTypes.API_KEYS ? (
        <Card>
          <FormStatus
            state="info"
            text={
              <>
                API keys should be treated with the same level of
                confidentiality as passwords, and should not be shared or
                exposed to unauthorized parties.
              </>
            }
          />
          <Space
            bottom="small"
            className={style['Credential-Container']}
            top="small"
          >
            <H4>Live keys</H4>
            <Space className={style['Credential-Card']}>
              {(liveClients as ApiKeyDto[]).map((apiKey) => (
                <Apikey
                  apiKey={apiKey}
                  isAdmin={isAdmin}
                  key={apiKey.id}
                  onDeleteClicked={() => onDeleteApiKey.execute(apiKey)}
                />
              ))}
            </Space>

            {!app.isProviderApp && (
              <>
                <H4 bottom="0" top="medium">
                  Test keys
                </H4>
                <Space className={style['Credential-Card']}>
                  {(testClients as ApiKeyDto[]).map((apiKey) => (
                    <Apikey
                      apiKey={apiKey}
                      isAdmin={isAdmin}
                      key={apiKey.id}
                      onDeleteClicked={() => onDeleteApiKey.execute(apiKey)}
                    />
                  ))}
                </Space>
              </>
            )}

            {app?.apiKeys.length === 0 && (
              <P>You currently have no API keys.</P>
            )}
          </Space>
          <Divider />
          {isAdmin && (
            <Space top="small">
              <Dialog
                title="New API key"
                triggerAttributes={{
                  variant: 'primary',
                  icon: 'add',
                  text: 'New key',
                }}
              >
                <CreateApiKeyForm
                  isProviderApp={app.isProviderApp}
                  onFormSubmit={async (data) =>
                    await onCreateApiKey.execute(data)
                  }
                />
              </Dialog>
            </Space>
          )}
          {error && <FormStatus text={error} top="medium" />}
        </Card>
      ) : (
        <Card>
          {(app?.clients.length !== 0 && (
            <>
              <FormStatus
                state="info"
                text={
                  <>
                    More clients can be created by clicking the New client
                    button. Note that we recommend having one client for each
                    environment.
                  </>
                }
              />
              <Space
                bottom="small"
                className={style['Credential-Container']}
                top="small"
              >
                <H4>Live Clients</H4>
                <Space className={style['Credential-Card']}>
                  {(liveClients as ClientDto[]).map((client) => (
                    <Client
                      ciamClient={ciamClients?.find((c) => c.id === client.id)}
                      ciamClientsLoading={ciamClientsLoading}
                      client={client}
                      currentApiAndScopeEntitlements={
                        app.currentApiAndScopeEntitlements
                      }
                      hasPrivilageToEditClient={hasClientManagementGrp}
                      isAdmin={isAdmin}
                      key={client.id}
                      noPrivilageError={tipMessage}
                      onClientUpdated={() => mutateCiamClients()}
                      onDeleteClicked={() => onDeleteClient(client.id)}
                      onDeleteFromPortalClicked={
                        featureFlags.ENABLE_CLIENT_IMPORT
                          ? () => onDeleteClientFromPortal.execute(client)
                          : null
                      }
                      project={app.project}
                    />
                  ))}
                </Space>
                {app?.clients.filter(({ liveMode }) => liveMode).length ==
                  0 && <NoData message="You currently have no live clients" />}
                <H4 bottom="0" top="medium">
                  Test Clients
                </H4>

                <Space className={style['Credential-Card']}>
                  {(testClients as ClientDto[]).map((client) => (
                    <Client
                      ciamClient={ciamClients?.find((c) => c.id === client.id)}
                      ciamClientsLoading={ciamClientsLoading}
                      client={client}
                      currentApiAndScopeEntitlements={
                        app.currentApiAndScopeEntitlements
                      }
                      hasPrivilageToEditClient={hasClientManagementGrp}
                      isAdmin={isAdmin}
                      key={client.id}
                      noPrivilageError={tipMessage}
                      onClientUpdated={() => mutateCiamClients()}
                      onDeleteClicked={() => onDeleteClient(client.id)}
                      onDeleteFromPortalClicked={
                        featureFlags.ENABLE_CLIENT_IMPORT
                          ? () => onDeleteClientFromPortal.execute(client)
                          : null
                      }
                      project={app.project}
                    />
                  ))}
                </Space>
                {app?.clients.filter(({ liveMode }) => !liveMode).length ==
                  0 && <NoData message="You currently have no test clients" />}
              </Space>
            </>
          )) || (
            <Space
              bottom="small"
              className={style['Credential-Container']}
              top="small"
            >
              <NoData message="You currently have no clients" />
            </Space>
          )}

          <Divider />

          <Space top="small">
            <Button
              disabled={!isAdmin || !hasClientManagementGrp}
              icon="add"
              icon_position="left"
              on_click={() =>
                navigate(
                  app.owner.type === 'team'
                    ? `/team/${app.owner.id}/application/${app.id}/create-client`
                    : `/application/${app.id}/create-client`,
                )
              }
              tooltip={
                !isAdmin || !hasClientManagementGrp ? (
                  <Tooltip size="large" style={{ maxWidth: '300px' }}>
                    {tipMessage}
                  </Tooltip>
                ) : undefined
              }
            >
              New Client
            </Button>
            {featureFlags.ENABLE_CLIENT_IMPORT && (
              <Button
                icon="download"
                icon_position="left"
                left="small"
                on_click={() =>
                  navigate(
                    app.owner.type === 'team'
                      ? `/team/${app.owner.id}/application/${app.id}/import-client`
                      : `/application/${app.id}/import-client`,
                  )
                }
              >
                Import Client
              </Button>
            )}
          </Space>
        </Card>
      )}
    </>
  );
}

type CreateApiKeyForm = {
  onFormSubmit: (data: CreateApiKeyRequestDto) => void;
  isProviderApp: boolean;
};

const CreateApiKeyForm = ({
  onFormSubmit,
  isProviderApp,
}: CreateApiKeyForm) => {
  const { register, handleSubmit, controller } = useEufemiaForm(
    createApiKeyFormSchema,
    isProviderApp ? { mode: 'live' } : {},
  );
  const onSubmit = handleSubmit(
    useCallback(async ({ mode }) => {
      onFormSubmit({ liveMode: mode == 'live' });
    }, []),
  );
  return (
    <FormSet bottom="0" on_submit={onSubmit} vertical>
      <FormRow>
        <Radio.Group
          {...register.radioGroup('mode')}
          bottom="large"
          label="What environment is the key for?"
          layout_direction="column"
        >
          <Radio
            disabled={isProviderApp}
            label="Test"
            top="small"
            value="test"
          />
          <Radio label="Live" top="small" value="live" />
        </Radio.Group>
      </FormRow>
      <Dialog.Action>
        <Button
          on_click={({ close }) => {
            close();
          }}
          text="Cancel"
          variant="secondary"
        />
        <Button
          on_click={({ close }) => {
            if (controller.values.mode) {
              close();
            }
          }}
          text="Create key"
          type="submit"
        />
      </Dialog.Action>
    </FormSet>
  );
};
