import { boolean, object, string, z } from 'zod';

import type { ProjectDto } from '../admin/ProjectDto';
import { UUID_MATCHER } from '../common/matchers';
import { INVALID_UUID } from '../common/messages';
import type { ApiWithStatusAndScopesDto, ApiWithStatusDto } from './ApiDto';
import type { ApiKeyDto } from './ApiKeyDto';
import type {
  AppApiEntitlementDto,
  CurrentApiAndScopeEntitlementsDto,
  EntitlementRequestWithScopeDto,
} from './AppApiEntitlementDto';
import type { AppScopeEntitlementDto } from './AppScopeEntitlementDto';
import type { ClientDto } from './ClientDto';

export enum AccessTypes {
  API_KEYS = 'API_KEYS',
  O_AUTH_SYSTEM_TO_SYSTEM = 'O_AUTH_SYSTEM_TO_SYSTEM',
}

export interface AppDtoV2 {
  id: string;
  name: string;
  description: string | null;
  owner: Owner;
  apiKeyName: string;
  createdAt: string;
  updatedAt: string;
  accessType: 'API_KEYS' | 'O_AUTH_SYSTEM_TO_SYSTEM' | null;
  internal: boolean | null;
}

export interface AppDto {
  id: string;
  name: string;
  description: string | null;
  owner: Owner;
  apiKeyName: string;
  apiKeys: ApiKeyDto[];
  createdAt: string;
  updatedAt: string;
  accessType: AccessTypes | null;
  internal: boolean | null;
}
export interface Owner {
  id: string;
  type: 'personal' | 'team';
}

//TODO: Delete liveModeApis and testModeApis. Rename too
export interface AppWithApisDto extends AppDto {
  liveModeApis: ApiWithStatusDto[];
  testModeApis: ApiWithStatusDto[];
  scopeEntitlements: AppScopeEntitlementDto[];
  apiEntitlements: AppApiEntitlementDto[];
}

export interface AppWithEntitlementsDto
  extends Pick<
    AppDto,
    | 'id'
    | 'name'
    | 'description'
    | 'owner'
    | 'apiKeys'
    | 'createdAt'
    | 'updatedAt'
    | 'accessType'
    | 'internal'
  > {
  apiKeys: ApiKeyDto[];
  clients: ClientDto[];
  currentApiAndScopeEntitlements: CurrentApiAndScopeEntitlementsDto[];
  pendingEntitlementRequests: EntitlementRequestWithScopeDto[];
  deniedEntitlementRequests: EntitlementRequestWithScopeDto[];
  notCompletedEntitlementRequests: EntitlementRequestWithScopeDto[];
  project: ProjectDto | null;
  providerApis?: ApiWithStatusAndScopesDto[];
  isProviderApp: boolean;
}

export const appDetailsInputSchema = object({
  name: string().min(1).max(255),
  description: string().max(500).optional(),
  teamId: string().regex(UUID_MATCHER, INVALID_UUID).nullish(),
  projectId: string().regex(UUID_MATCHER, INVALID_UUID).nullish(),
  internal: boolean().optional(),
  accessType: z
    .string()
    .and(z.enum([AccessTypes.API_KEYS, AccessTypes.O_AUTH_SYSTEM_TO_SYSTEM]))
    .default(AccessTypes.API_KEYS),
});

export interface CreateAppResponseDto {
  id: string;
  name: string;
  description: string;
  teamId: string;
  internal: boolean;
  accessType: Array<AccessTypes.API_KEYS | AccessTypes.O_AUTH_SYSTEM_TO_SYSTEM>;
}
export type CreateAppRequestDto = z.infer<typeof appDetailsInputSchema>;

export const appUpdateInputSchema = object({
  name: string().min(1).max(255).optional(),
  description: string().max(500).optional(),
});

export type UpdateAppRequestDto = z.infer<typeof appUpdateInputSchema>;

export type UpdateAppResponseDto = {
  name: string;
  description: string;
  id: string;
};
