import { Accordion, Card, H4, P } from '@dnb/eufemia';
import { shield_lock_medium as ShieldLockIcon } from '@dnb/eufemia/icons';
import { ApiWithStatusAndScopesDto } from '@portals/shared/portal/ApiDto';

import OAuthIntegrationInfoRequesterComp from '@/pages/OAuthIntegrationInfoRequesterPage/components/OAuthIntegrationInfoRequester';

interface Props {
  api: ApiWithStatusAndScopesDto;
  apiUpdated: () => void;
}

export default function ServiceNowSection({ api, apiUpdated }: Props) {
  return (
    <Card align="stretch">
      <Accordion
        expanded={!api.enableServiceNowRequest}
        icon_position="right"
        space
        variant="plain"
      >
        <Accordion.Header>
          <H4>
            <ShieldLockIcon /> Access Management - ServiceNow
          </H4>
        </Accordion.Header>
        <Accordion.Content>
          <P bottom="small">
            Provide the following information to set up the API for supporting
            client management from the developer portal
          </P>
          <OAuthIntegrationInfoRequesterComp
            api={api}
            apiUpdated={apiUpdated}
            key={api.id}
          />
        </Accordion.Content>
      </Accordion>
    </Card>
  );
}
