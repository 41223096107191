import { Flex, ProgressIndicator } from '@dnb/eufemia';
import { GlobalStatus } from '@dnb/eufemia';
import { ContentDto } from '@portals/shared/portal/ContentDto';
import useSWR from 'swr';

import Guide from '@/components/Guide';

export default function DeveloperPortalGuideContent({
  contentSlug,
}: {
  contentSlug: string;
}): JSX.Element {
  const { data: content, isValidating: loading } = useSWR<ContentDto[]>(
    `/content/tpp/${DEVELOPER_PORTAL_TEAM_ID}/${contentSlug}`,
  );

  if (loading || !content)
    return (
      <Flex.Horizontal justify="center">
        <ProgressIndicator />
      </Flex.Horizontal>
    );

  return content.length === 0 ? (
    <GlobalStatus
      hide_close_button
      no_animation
      show
      state="error"
      text="We can't find the content you're looking for. Please contact us or try again later."
      title="Oops! Something went wrong."
    />
  ) : (
    <Guide guide={content[0].blob.content} />
  );
}
