/* eslint-disable max-lines */
import {
  Checkbox,
  Div,
  Drawer,
  Flex,
  FormRow,
  FormSet,
  Grid,
  Icon,
  InfoCard,
  Input,
  P,
  Radio,
  Tag,
} from '@dnb/eufemia';
import { filter as filterIcon } from '@dnb/eufemia/icons';
import { useMedia } from '@dnb/eufemia/shared';
import type { ApiDto } from '@portals/shared/portal/ApiDto';
import { useEufemiaForm } from '@portals/shared-frontend/hooks';
import { PropsWithChildren, useMemo, useState } from 'react';
import { object } from 'zod';

import HeroPage from '@/components/HeroPage';
import { createDummyApi } from '@/dummyData';
import useEnterPress from '@/hooks/useEnterPress';
import useFeatureFlags from '@/hooks/useFeatureFlags';
import { removeStringFromArray } from '@/utils/arrayUtils';
import { capitalizeFirstLetterOfString } from '@/utils/textUtils';

import { API_EXPLORER_TABS } from '../../content';
import ApiCard from '../ApiCard';
import CommonRepoApiInfoCard from '../CommonRepoApiInfoCard';
import { apiByCategory, Categories } from './helpers';

import style from './index.module.css';

const DUMMY_APIS = new Array(10).fill(null).map((_, id) => createDummyApi(id));

type ApiExplorerProps = PropsWithChildren<{
  title: string;
  apiType?: API_EXPLORER_TABS;
  apis: ApiDto[] | null;
  onApiFavouriteChange: (apiId: string, isFavorite: boolean) => void;
}>;

export default function ApiPage({
  title,
  apiType = API_EXPLORER_TABS.Commercial,
  apis,
  onApiFavouriteChange,
  children,
}: ApiExplorerProps): JSX.Element {
  const { featureFlags } = useFeatureFlags();
  const { isLarge } = useMedia();
  const [tagFilter, setTagFilter] = useState<string[]>([]);
  const [classificationFilter, setClassificationFilter] = useState<string[]>(
    [],
  );
  const [categoryFilter, setCategoryFilter] = useState<Categories | null>(null);
  const [query, setQuery] = useState('');

  const filteredApis = useMemo(() => {
    if (!apis) {
      return DUMMY_APIS;
    }
    const lowerCasedQuery = query.toLowerCase();

    const basis: ApiDto[] = apis
      .filter(
        (api) =>
          !lowerCasedQuery || api.name.toLowerCase().includes(lowerCasedQuery),
      )
      .filter(
        (api) =>
          tagFilter.length === 0 ||
          api.tags.some((tag) => tagFilter.includes(tag)),
      )
      .filter(
        (api) =>
          classificationFilter.length === 0 ||
          classificationFilter[0] === 'all' ||
          classificationFilter.includes(api.classification),
      )
      .filter((api) => apiByCategory(api, categoryFilter));

    return basis;
  }, [apis, query, tagFilter, classificationFilter, categoryFilter]);

  const tagCounts = useMemo(() => {
    if (!apis) {
      return {};
    }
    return apis
      .filter((api) =>
        classificationFilter[0]
          ? classificationFilter[0] === api.classification ||
            classificationFilter[0] === 'all'
          : api,
      )
      .filter((api) => apiByCategory(api, categoryFilter))
      .flatMap((api) => api.tags)
      .reduce<Record<string, number>>((result, tag) => {
        result[tag] = result[tag] ? ++result[tag] : 1;
        return result;
      }, {});
  }, [apis, categoryFilter, classificationFilter]);

  const classificationCount = useMemo(() => {
    if (!apis) {
      return {};
    }
    return apis
      .flatMap((api) => api.classification)
      .reduce<Record<string, number>>((result, classification) => {
        result[classification] = result[classification]
          ? ++result[classification]
          : 1;
        return result;
      }, {});
  }, [apis]);

  const { handleSubmit } = useEufemiaForm(object({}), {});

  const tags = useMemo(() => {
    if (!apis) {
      return [];
    }

    return [...new Set(apis.flatMap((api) => api.tags))];
  }, [apis]);

  const classifications = useMemo(() => {
    const set = new Set<string>();

    if (!apis) {
      return [];
    }

    set.add('all');
    for (const { classification } of apis || []) {
      set.add(classification);
    }

    return Array.from(set);
  }, [apis]);

  const [filterMenuOpen, setFilterMenuOpen] = useState<boolean>(false);

  const handleTabulationNavigation = (e: KeyboardEvent) => {
    const target = e.target as HTMLDivElement;

    const isFilterMenuOpen = target?.id === 'filterButton';
    if (isFilterMenuOpen) {
      setFilterMenuOpen(!filterMenuOpen);
    }
  };

  const filterRef = useEnterPress(handleTabulationNavigation);

  return (
    <HeroPage
      heroIllustration={require('@/illustrations/DNB_Supergraphics_Duo_summer_emerald.avif?url')}
      noContainer
      skeleton={!apis}
      title={title}
    >
      <Grid.Container columnGap rowGap>
        {children && (
          <Grid.Item span={{ small: 'full', large: [5, 12] }}>
            <Div>{children}</Div>
          </Grid.Item>
        )}
        <Grid.Item
          className={style['activeFilterBox']}
          span={{ small: 'full', medium: 'full', large: [1, 4] }}
        >
          <Flex.Vertical space="small">
            <FormRow top="x-small">
              <Drawer
                id="tagFilterDrawer"
                onClose={() => setFilterMenuOpen(false)}
                openState={filterMenuOpen}
                title="Filters"
                trigger={(props) => (
                  <div id="filterButton" ref={filterRef} tabIndex={0}>
                    <Flex.Horizontal
                      {...props}
                      align="center"
                      className={style['filterTitle']}
                      gap="small"
                      justify="flex-start"
                    >
                      <Icon icon={filterIcon} />
                      <P modifier="medium">Filters</P>
                    </Flex.Horizontal>
                  </div>
                )}
              >
                <Div className={style['flyoutOptionWrapper']}>
                  <FormSet
                    direction="vertical"
                    on_submit={handleSubmit(() => {})}
                  >
                    {apiType === API_EXPLORER_TABS.Internal && (
                      <FormRow top="medium">
                        <P modifier="medium" space={{ bottom: 'x-small' }}>
                          API type
                        </P>
                        <Radio.Group
                          layout_direction="column"
                          name="Api type"
                          on_change={({ value }) =>
                            value
                              ? setClassificationFilter([value])
                              : setClassificationFilter(
                                  removeStringFromArray(
                                    classificationFilter,
                                    value,
                                  ),
                                )
                          }
                        >
                          {classifications.map((type) => (
                            <Radio
                              checked={classificationFilter.includes(type)}
                              key={type}
                              label={`${capitalizeFirstLetterOfString(type)} (${
                                type === 'all'
                                  ? (apis?.length ?? 0)
                                  : (classificationCount[type] ?? 0)
                              })`}
                              size="medium"
                              value={type}
                            />
                          ))}
                        </Radio.Group>
                      </FormRow>
                    )}
                    {apiType === API_EXPLORER_TABS.Review && (
                      <FormRow top="medium">
                        <P modifier="medium" space={{ bottom: 'x-small' }}>
                          API Categories
                        </P>
                        <Radio.Group
                          layout_direction="column"
                          name="Api Category"
                          on_change={({ value }) =>
                            value && setCategoryFilter(value)
                          }
                        >
                          {Object.values(Categories).map((category) => (
                            <Radio
                              checked={categoryFilter === category}
                              key={category}
                              label={category}
                              size="medium"
                              value={category}
                            />
                          ))}
                        </Radio.Group>
                      </FormRow>
                    )}

                    <FormRow top="medium">
                      <P modifier="medium" space={{ bottom: 'x-small' }}>
                        Tags
                      </P>
                      {tags.length === 0 && 'No tags found'}
                      {tags.map((tag) => (
                        <Checkbox
                          checked={tagFilter.includes(tag)}
                          key={tag}
                          label={`${tag} (${tagCounts[tag] ?? 0})`}
                          onChange={({ checked }) =>
                            checked
                              ? setTagFilter([...tagFilter, tag])
                              : setTagFilter(
                                  removeStringFromArray(tagFilter, tag),
                                )
                          }
                          space={{ top: 'xx-small', bottom: 'xx-small' }}
                          value={tag}
                        />
                      ))}
                    </FormRow>
                  </FormSet>
                </Div>
              </Drawer>
            </FormRow>

            {isLarge && <P modifier="medium">Active filters</P>}
            <Tag.Group innerSpace="0" label="Filters" top="small">
              {categoryFilter && (
                <Tag onDelete={() => setCategoryFilter(null)}>
                  {categoryFilter}
                </Tag>
              )}
              {tagFilter.length > 0 &&
                tagFilter.map((tag) => (
                  <Tag
                    key={tag}
                    onDelete={() =>
                      setTagFilter(
                        tagFilter.filter((candidate) => candidate !== tag),
                      )
                    }
                  >
                    {tag}
                  </Tag>
                ))}
              {classificationFilter.length > 0 &&
                apiType === API_EXPLORER_TABS.Internal &&
                classificationFilter.map((classification) => (
                  <Tag
                    key={classification}
                    onDelete={() =>
                      setClassificationFilter(
                        classificationFilter.filter(
                          (candidate) => candidate !== classification,
                        ),
                      )
                    }
                  >
                    {classification}
                  </Tag>
                ))}
            </Tag.Group>
          </Flex.Vertical>
        </Grid.Item>

        <Grid.Item span={{ small: 'full', large: [5, 12] }}>
          <FormRow bottom="small">
            <Input
              clear
              icon="loupe"
              on_change={({ value }) => setQuery(value)}
              placeholder="Search"
              stretch
              value={query}
            />
          </FormRow>
          <P modifier="medium" space={{ top: '1rem' }}>
            Showing {filteredApis.length} of {apis?.length} apis
          </P>
          <Flex.Vertical
            align="stretch"
            gap="xx-small"
            space={{ top: 'small', bottom: 'small' }}
          >
            {filteredApis.length > 0 ? (
              <div>
                {filteredApis.map((api) => (
                  <ApiCard
                    api={api}
                    key={api.id}
                    onApiFavouriteChange={(apiId, isFavorite) =>
                      onApiFavouriteChange(apiId, isFavorite)
                    }
                  />
                ))}
              </div>
            ) : featureFlags.ENABLE_COMMON_REPO_API ? (
              <CommonRepoApiInfoCard />
            ) : (
              <InfoCard text="No APIs match the selected filters." />
            )}
          </Flex.Vertical>
        </Grid.Item>
      </Grid.Container>
    </HeroPage>
  );
}
