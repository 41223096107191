import HeroPage from '@/components/HeroPage';

import DeveloperPortalGuideContent from '../../components/Guide/DeveloperPortalGuideContent';

export default function LandingNew(): JSX.Element {
  return (
    <HeroPage
      heroIllustration={require('@/illustrations/DNB_Supergraphics_front_page.avif?url')}
      noContainer
      noTopMargin
      subtitle="Follow these steps to start consuming our APIs"
      title="Getting started"
    >
      <DeveloperPortalGuideContent contentSlug="getting-started" />
    </HeroPage>
  );
}
