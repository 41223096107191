import { Flex, H3 } from '@dnb/eufemia';
import {
  email_medium as EmailIcon,
  list as ListIcon,
  person as PersonIcon,
  shield_lock_medium as ShieldLockIcon,
  speedometer as SpeedometerIcon,
} from '@dnb/eufemia/icons';
import type { ApiWithStatusAndScopesDto } from '@portals/shared/portal/ApiDto';
import { PropsWithChildren, ReactNode } from 'react';
import { useParams } from 'react-router-dom';
import useSWR from 'swr';

import BackButton from '@/components/BackButton';
import Page from '@/components/Page';
import AdditionalInformationSection from '@/pages/profile/apps/application/api-stats/AdditionalInformationSection';
import DashboardSection from '@/pages/profile/apps/application/api-stats/DashboardSection';
import PublishApiSection from '@/pages/profile/apps/application/api-stats/PublishApiSection';
import ScopeRequestSection from '@/pages/profile/apps/application/api-stats/ScopeRequestSection';
import ScopesSection from '@/pages/profile/apps/application/api-stats/ScopesSection';
import ServiceNowSection from '@/pages/profile/apps/application/api-stats/ServiceNowSection';
import SubscribersSection from '@/pages/profile/apps/application/api-stats/SubscribersSection';

import ApiDetails from '../components/ApiDetails';

export default function ApiStatistics() {
  const { appId, apiId } = useParams();

  const {
    data: api,
    isValidating: loadingApi,
    mutate,
  } = useSWR<ApiWithStatusAndScopesDto>(
    appId && apiId && `/apps/${appId}/apis/${apiId}`,
  );

  if (!api) {
    return null;
  }

  return (
    <Page
      description={
        <ApiDetails
          apiClassification={api.classification}
          apiDescription={api.description}
          slug={api.slug}
          stage={api.stage}
        />
      }
      skeleton={loadingApi}
      styleType="black-3"
      title={api.name}
    >
      <BackButton bottom="medium" to="./../..">
        Back to app page
      </BackButton>
      {appId && (
        <Flex.Stack wrap={false}>
          <SectionWithTitle icon={<PersonIcon />} title="ServiceNow">
            <ServiceNowSection api={api} apiUpdated={mutate} />
          </SectionWithTitle>

          {api.stage === 'launched' ? (
            <SectionWithTitle icon={<SpeedometerIcon />} title="Dashboard">
              <DashboardSection apiId={api.id} appId={appId} />
            </SectionWithTitle>
          ) : (
            <SectionWithTitle
              icon={<ListIcon />}
              title="Publish integration service"
            >
              <PublishApiSection api={api} appId={appId} />
            </SectionWithTitle>
          )}

          <SectionWithTitle
            icon={<ShieldLockIcon />}
            title="Additional information"
          >
            <AdditionalInformationSection api={api} appId={appId} />
          </SectionWithTitle>

          <SectionWithTitle icon={<ShieldLockIcon />} title="Scopes">
            <ScopesSection api={api} />
          </SectionWithTitle>

          <SectionWithTitle icon={<ShieldLockIcon />} title="Scope requests">
            <ScopeRequestSection api={api} />
          </SectionWithTitle>

          <SectionWithTitle icon={<EmailIcon />} title="Subscribers">
            <H3 bottom="small" />
            <SubscribersSection apiId={api.id} />
          </SectionWithTitle>
        </Flex.Stack>
      )}
    </Page>
  );
}

function SectionWithTitle({
  title,
  icon,
  children,
}: PropsWithChildren<{ title: string; icon: ReactNode }>) {
  return (
    <div>
      <H3 bottom="small">
        {icon} {title}
      </H3>
      {children}
    </div>
  );
}
