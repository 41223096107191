import HeroPage from '@/components/HeroPage';

import DeveloperPortalGuideContent from '../../../components/Guide/DeveloperPortalGuideContent';

export default function GettingStartedAPIConsumers() {
  return (
    <HeroPage
      heroIllustration={require('@/illustrations/DNB_Supergraphics_front_page.avif?url')}
      noContainer
      noTopMargin
      subtitle="Follow these steps to start consuming APIs"
      title="Getting started for DNB internal API consumers"
    >
      <DeveloperPortalGuideContent contentSlug="consuming-apis" />
    </HeroPage>
  );
}
