import {
  Drawer,
  Flex,
  FormRow,
  FormStatus,
  Grid,
  Icon,
  InfoCard,
  Input,
  P,
  ProgressIndicator,
  Tag,
} from '@dnb/eufemia';
import { filter } from '@dnb/eufemia/icons';
import { useMedia } from '@dnb/eufemia/shared';
import { EventDto } from '@portals/shared/portal/EventDto';
import { useEffect, useState } from 'react';
import useSWR from 'swr';

import HeroPage from '@/components/HeroPage';
import EventList from '@/pages/explorer/events/EventList';

import EventFilter from './EventFilter';

import style from './EventExplorerPage.module.css';

export default function EventExplorerPage() {
  const {
    data: events,
    isValidating: isLoading,
    error: error,
  } = useSWR<EventDto[], Error>('/v2/events', { onError: () => {} });
  const { isLarge } = useMedia();

  const [filteredEvents, setFilteredEvents] = useState<EventDto[]>([]);
  const [activeFilters, setActiveFilters] = useState<string[]>([]);
  const [query, setQuery] = useState<string>('');
  const [debouncedQuery, setDebouncedQuery] = useState<string>(query);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedQuery(query);
    }, 300);

    return () => {
      clearTimeout(handler);
    };
  }, [query]);

  useEffect(() => {
    if (!events) return;

    const lowerCaseQuery = debouncedQuery.toLowerCase();
    const eventsFiltered = events.filter((event) => {
      const matchesFilter =
        activeFilters.length === 0 ||
        activeFilters.includes(event.classification);

      if (!matchesFilter) return false;

      const matchesQuery = [event.name, event.slug].some(
        (field) => field && field.toLowerCase().includes(lowerCaseQuery),
      );

      return matchesQuery;
    });

    setFilteredEvents(eventsFiltered);
  }, [events, debouncedQuery, activeFilters]);

  return (
    <div className="dark-mode">
      <HeroPage
        heroIllustration={require('@/illustrations/DNB_Supergraphics_Duo_summer_emerald.avif?url')}
        noContainer
        title="Events Explorer"
      >
        <Grid.Container columnGap rowGap>
          <Grid.Item
            className={style['activeFilterBox']}
            span={{ small: 'full', medium: 'full', large: [1, 4] }}
          >
            <Flex.Vertical space="small">
              <FormRow top="x-small">
                <Drawer
                  id="tagFilterDrawer"
                  title="Filters"
                  trigger={(props) => (
                    <Flex.Horizontal
                      {...props}
                      align="center"
                      className={style['filterTitle']}
                      gap="small"
                      justify="flex-start"
                    >
                      <Icon icon={filter} />
                      <P modifier="medium">Filters</P>
                    </Flex.Horizontal>
                  )}
                >
                  <div className={style['flyoutOptionWrapper']}>
                    <FormRow top="medium">
                      <EventFilter
                        activeFilters={activeFilters}
                        setActiveFilters={setActiveFilters}
                      />
                    </FormRow>
                  </div>
                </Drawer>
              </FormRow>

              {isLarge && <P modifier="medium">Active filters</P>}
              <Tag.Group innerSpace="0" label="Filters" top="small">
                {activeFilters.length > 0 &&
                  activeFilters.map((tag) => (
                    <Tag
                      key={tag}
                      onClick={() =>
                        setActiveFilters(
                          activeFilters.filter(
                            (candidate) => candidate !== tag,
                          ),
                        )
                      }
                      variant="removable"
                    >
                      {tag}
                    </Tag>
                  ))}
              </Tag.Group>
            </Flex.Vertical>
          </Grid.Item>

          <Grid.Item span={{ small: 'full', large: [5, 12] }}>
            <FormRow bottom="small">
              <Input
                clear
                icon="loupe"
                on_change={({ value }) => setQuery(value)}
                placeholder="Search"
                stretch
                value={query}
              />
            </FormRow>
            <P modifier="medium" space={{ top: '1rem' }}>
              Showing {filteredEvents.length ?? 0} of {events?.length ?? 0}{' '}
              events
            </P>
            <Flex.Vertical
              align="stretch"
              gap="xx-small"
              style={{ margin: '1rem 0' }}
            >
              {error ? (
                <FormStatus stretch text="Failed to fetch events." />
              ) : isLoading ? (
                <ProgressIndicator />
              ) : filteredEvents.length === 0 ? (
                <InfoCard text="No events match the selected filters." />
              ) : (
                <EventList events={filteredEvents} />
              )}
            </Flex.Vertical>
          </Grid.Item>
        </Grid.Container>
      </HeroPage>
    </div>
  );
}
