import { Card, Link, Switch } from '@dnb/eufemia';
import { ApiWithStatusAndScopesDto } from '@portals/shared/portal/ApiDto';
import type { ChangelogDto } from '@portals/shared/portal/ChangelogDto';
import { useLocalStorage } from '@portals/shared-frontend/hooks';
import useSWR from 'swr';

import Divider from '@/components/Divider';
import Markdown from '@/components/Markdown';
import AdditionalInformation from '@/pages/profile/apps/application/api-stats/AdditionalInformationSection/AdditionalInformation';
import { swrExpect404 } from '@/utils/swrUtils';

import { enableApiFaq, enableApiStatus, enableChangelogs } from './constants';

interface Props {
  appId: string;
  api: ApiWithStatusAndScopesDto;
}

export default function AdditionalInformationSection({ appId, api }: Props) {
  const { data: changelogs, isValidating: loadingChangelogs } = useSWR<
    ChangelogDto[]
  >(`/api/${api.slug}/environments/@default/changelogs`, swrExpect404);

  const [displayHelpText, setDisplayHelpText] = useLocalStorage(
    'displayHelpText',
    false,
  );

  return (
    <Card skeleton={loadingChangelogs} stack>
      <Switch
        bottom="small"
        checked={Boolean(displayHelpText)}
        label="Display help message"
        onChange={(e) => setDisplayHelpText(e.checked)}
      />
      <AdditionalInformation
        content="API is attachable"
        displayHelpText={displayHelpText}
        statusMessage={<StatusMessage />}
        value={api.attachable}
      />
      <Divider />
      <AdditionalInformation
        content="Access management enabled"
        displayHelpText={displayHelpText}
        statusMessage={<StatusMessage />}
        value={api.enableServiceNowRequest}
      />
      <Divider />
      <AdditionalInformation
        codeBlock={
          <Markdown>
            {enableChangelogs(api.slug, appId!, '@default', '@latest')}
          </Markdown>
        }
        content="Changelogs enabled"
        displayHelpText={displayHelpText}
        statusMessage={
          <StatusMessage
            context="Changelogs"
            helpMessage={false}
            link="https://developer.dnb.no/documentation/developer-portal-api/prod/@latest/reference#tags/Changelog"
          />
        }
        value={!!(changelogs && changelogs.length > 0)}
      />
      <Divider />
      <AdditionalInformation
        codeBlock={<Markdown>{enableApiStatus(api.slug, appId!)}</Markdown>}
        content="Status reporting enabled"
        displayHelpText={displayHelpText}
        statusMessage={
          <StatusMessage
            context="status reporting"
            helpMessage={false}
            link="https://developer.dnb.no/documentation/developer-portal-api/prod/@latest/reference#tags/APIs/operation/patch/apis/api-slug"
          />
        }
        value={api.enableStatus}
      />
      <Divider />
      <AdditionalInformation
        codeBlock={<Markdown>{enableApiFaq(api.slug, appId!)}</Markdown>}
        content="FAQ enabled"
        displayHelpText={displayHelpText}
        statusMessage={
          <StatusMessage
            context="FAQ"
            helpMessage={false}
            link="https://developer.dnb.no/documentation/developer-portal-api/prod/@latest/guide"
          />
        }
        value={api.enableFaq}
      />
    </Card>
  );
}

type StatusMessageProps = {
  link?: string;
  context?: string;
  helpMessage?: boolean;
};

function StatusMessage({
  link,
  context,
  helpMessage = true,
}: StatusMessageProps) {
  return helpMessage ? (
    <>
      Contact the portals team to get started. You can reach them on the
      following slack channel:{' '}
      <Link
        href="https://dnb.enterprise.slack.com/archives/CDXF91B7E"
        target="_blank"
      >
        #developer-portal-help
      </Link>
    </>
  ) : (
    <>
      Get started with {context} for your API, by using the{' '}
      <Link href={link} target="_blank">
        developer portal API
      </Link>
    </>
  );
}
