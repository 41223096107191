/* eslint-disable max-lines */
import {
  Button,
  Checkbox,
  FormRow,
  FormSet,
  FormStatus,
  H4,
  Input,
  P,
  Skeleton,
  Table,
  Td,
  Tooltip,
  Tr,
} from '@dnb/eufemia';
import styled from '@emotion/styled';
import { CurrentApiAndScopeEntitlementsDto } from '@portals/shared/portal/AppApiEntitlementDto';
import type {
  CiamClientDto,
  ClientDto,
} from '@portals/shared/portal/ClientDto';
import { UpdateOAuthClientRequest } from '@portals/shared/portal/OAuthClientDto';
import { useEufemiaForm, useFieldArray } from '@portals/shared-frontend/hooks';
import { ApiError } from '@portals/shared-frontend/utils/ApiError';
import { useCallback, useMemo, useState } from 'react';
import { z } from 'zod';

import { deleteClient, updateClient } from '@/api/app';
import Card from '@/components/Card';
import LoadingModal from '@/components/LoadingModal';
import Toast from '@/components/Toast';

export type ClientProps = {
  isAdmin: boolean;
  client: ClientDto;
  ciamClient?: CiamClientDto;
  currentApiAndScopeEntitlements: CurrentApiAndScopeEntitlementsDto[];
  onClientUpdated?: () => void;
  onDeleteClicked?: () => void;
  ciamClientsLoading: boolean;
  hasPrivilageToEditClient: boolean;
  noPrivilageError: React.ReactNode;
  variant: 'DELETE' | 'EDIT';
};

const scopesFormSchema = z.object({
  scopes: z.array(z.string()).default([]),
  crq: z.string(),
});

export default function ClientEdit({
  isAdmin,
  client,
  ciamClient,
  currentApiAndScopeEntitlements,
  onClientUpdated,
  onDeleteClicked,
  ciamClientsLoading,
  hasPrivilageToEditClient,
  noPrivilageError,
  variant,
}: ClientProps): JSX.Element | null {
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [successMessage, setSuccessMessage] = useState<null | string>(null);

  const { controller, handleSubmit, register, submitting } = useEufemiaForm(
    scopesFormSchema,
    {
      scopes: ciamClient?.scopes || [],
      crq: client.liveMode ? undefined : '',
    },
  );

  const { push, remove } = useFieldArray(controller, 'scopes');

  const onScopeChanged = (scopeName: string, checked: boolean) => {
    if (checked) {
      if (!controller.values?.scopes?.includes(scopeName)) push(scopeName);
    } else {
      const indexToRemove = controller.values?.scopes?.indexOf(scopeName);
      if (indexToRemove != undefined && indexToRemove >= 0) {
        remove(indexToRemove);
      }
    }
  };

  const onSubmit = handleSubmit(
    useCallback(async ({ scopes, crq }) => {
      try {
        if (variant === 'DELETE') {
          await deleteClient(client.appId, client.id, crq);
          setErrorMessage(null);
          setSuccessMessage('Client deleted successfully');
          if (onDeleteClicked) {
            onDeleteClicked();
          }
          setTimeout(() => {
            setSuccessMessage(null);
          }, 5000);
          return;
        }
        const data: UpdateOAuthClientRequest = {
          scopes,
          liveMode: client.liveMode,
          redirectUris: [],
        };

        await updateClient(client.appId, client.id, data, crq);
        setErrorMessage(null);
        setSuccessMessage('Client updated successfully');
        if (onClientUpdated) {
          onClientUpdated();
        }
        controller.setValue('crq', undefined);
        setTimeout(() => {
          setSuccessMessage(null);
        }, 5000);
      } catch (error: unknown) {
        if (ApiError.isApiError(error)) {
          setErrorMessage(error.body.message);
        } else {
          throw error;
        }
      }
    }, []),
  );

  const extraScopesInClient = useMemo(() => {
    // If the client has scopes that are not in the current entitlements, add them to the form
    return (
      ciamClient?.scopes.filter(
        (scope) =>
          !currentApiAndScopeEntitlements.some((api) =>
            api.scopeEntitlements.some((s) => s.scopeName === scope),
          ),
      ) || []
    );
  }, [ciamClient?.scopes, currentApiAndScopeEntitlements]);

  return (
    <FormSet left="0" on_submit={onSubmit} right="0">
      <Skeleton show={ciamClientsLoading}>
        {submitting && <LoadingModal />}
        {variant === 'DELETE' ? (
          <FormStatus bottom="medium" state="warn" top="medium">
            Are you sure to delete this client? This action cannot be undone.
          </FormStatus>
        ) : (
          <>
            {currentApiAndScopeEntitlements.map((entitlement) => (
              <SpacedCard key={entitlement.api.id}>
                <H4>{entitlement.api.name}</H4>
                <Table>
                  <tbody>
                    {entitlement.scopeEntitlements
                      .filter((s) => s.liveMode === ciamClient?.liveMode)
                      .map((scopeEntitlement) => (
                        <Tr key={scopeEntitlement.scopeName}>
                          <Td>
                            <Checkbox
                              checked={controller.values?.scopes?.includes(
                                scopeEntitlement.scopeName,
                              )}
                              disabled={!isAdmin}
                              label={
                                <BreakOnSpecialChars
                                  data-content={scopeEntitlement.scopeName.replaceAll(
                                    /[.-_]/g,
                                    '$&\u200B',
                                  )}
                                />
                              }
                              onChange={({ checked }) =>
                                onScopeChanged(
                                  scopeEntitlement.scopeName,
                                  checked,
                                )
                              }
                            />
                          </Td>
                        </Tr>
                      ))}
                    {entitlement.scopeEntitlements.filter(
                      (s) => s.liveMode === ciamClient?.liveMode,
                    ).length === 0 && <P>No scopes available</P>}
                  </tbody>
                </Table>
              </SpacedCard>
            ))}
            {extraScopesInClient.length > 0 && (
              <SpacedCard>
                <H4>Extra scopes in clients</H4>
                <FormStatus bottom="small" state="warn" top="small">
                  This client is consuming more scopes than approved for this
                  app.
                </FormStatus>
                {extraScopesInClient.map((scope) => (
                  <Tr key={scope}>
                    <Td>
                      <Checkbox
                        checked={controller.values?.scopes?.includes(scope)}
                        disabled={!isAdmin}
                        label={
                          <BreakOnSpecialChars
                            data-content={scope.replaceAll(
                              /[.-_]/g,
                              '$&\u200B',
                            )}
                          />
                        }
                        onChange={({ checked }) =>
                          onScopeChanged(scope, checked)
                        }
                      />
                    </Td>
                  </Tr>
                ))}
              </SpacedCard>
            )}
          </>
        )}
        {isAdmin && client.liveMode && (
          <SpacedCard>
            <>
              <P bold>ServiceNow CRQ</P>
              <Table>
                <tbody>
                  <Tr>
                    <Td>
                      <Input
                        label="CRQ must be in implementation window."
                        label_direction="vertical"
                        right="small"
                        size="large"
                        stretch
                        value=""
                        {...register.input('crq')}
                      />
                    </Td>
                  </Tr>
                </tbody>
              </Table>
            </>
          </SpacedCard>
        )}
        {errorMessage && (
          <FormRow>
            <FormStatus bottom="large" text={errorMessage} top="medium" />
          </FormRow>
        )}
        <FormRow>
          <Button
            disabled={!isAdmin || !hasPrivilageToEditClient}
            left="small"
            size="large"
            text={variant === 'DELETE' ? 'Delete client' : 'Update client'}
            tooltip={
              !isAdmin || !hasPrivilageToEditClient ? (
                <Tooltip size="large" style={{ maxWidth: '300px' }}>
                  {noPrivilageError}
                </Tooltip>
              ) : undefined
            }
            top="small"
            type="submit"
          />
        </FormRow>
      </Skeleton>
      {successMessage && (
        <Toast message={successMessage} position="bottom" variant="success" />
      )}
    </FormSet>
  );
}

const SpacedCard = styled(Card)`
  margin-top: 1rem;
`;

const BreakOnSpecialChars = styled.span`
  word-break: break-word;
  white-space: normal;

  /* Replace known separators with zero-width space for breaking */
  &::before {
    content: attr(data-content);
    word-break: break-word;
  }
`;
