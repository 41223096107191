import {
  Dialog,
  Div,
  Drawer,
  Flex,
  H2,
  Hr,
  P,
  Span,
  Tooltip,
} from '@dnb/eufemia';
import { WarnIcon } from '@dnb/eufemia/components/FormStatus';
import { edit as EditIcon, trash as TrashIcon } from '@dnb/eufemia/icons';
import { isSetEqual } from '@portals/shared/common/utils';
import { CurrentApiAndScopeEntitlementsDto } from '@portals/shared/portal/AppApiEntitlementDto';
import type {
  CiamClientDto,
  ClientDto,
} from '@portals/shared/portal/ClientDto';
import { ProjectDto } from '@portals/shared/portal/ProjectDto';
import { useMemo } from 'react';

import Card from '@/components/Card';
import LoadingModal from '@/components/LoadingModal';

import ClientEdit from '../ClientEdit';

import style from './index.module.css';

export type ClientProps = {
  isAdmin: boolean;
  hasPrivilageToEditClient: boolean;
  client: ClientDto;
  ciamClient?: CiamClientDto;
  onDeleteFromPortalClicked?: (() => void) | null;
  currentApiAndScopeEntitlements: CurrentApiAndScopeEntitlementsDto[];
  ciamClientsLoading: boolean;
  onClientUpdated: () => void;
  noPrivilageError: React.ReactNode;
  onDeleteClicked: () => void;
  project: ProjectDto | null;
};

export default function Client({
  isAdmin,
  client,
  onDeleteFromPortalClicked,
  ciamClient,
  currentApiAndScopeEntitlements,
  ciamClientsLoading,
  onClientUpdated,
  hasPrivilageToEditClient,
  noPrivilageError,
  onDeleteClicked,
  project,
}: ClientProps): JSX.Element | null {
  const isScopesEqual = useMemo(
    () =>
      isSetEqual(
        new Set(
          currentApiAndScopeEntitlements.flatMap((entitlement) =>
            entitlement.scopeEntitlements
              .filter((scopeEnt) => scopeEnt.liveMode === client.liveMode)
              .map((scopeEnt) => scopeEnt.scopeName),
          ),
        ),
        new Set(ciamClient?.scopes?.map((scope) => scope)),
      ),
    [currentApiAndScopeEntitlements, ciamClient?.scopes, client.liveMode],
  );

  const isCiamApplicationSame = useMemo(() => {
    const ciamAppId = ciamClient?.liveMode
      ? project?.ciamProdAppId
      : project?.ciamUatAppId;

    return ciamClient?.applicationId === ciamAppId;
  }, [
    ciamClient?.applicationId,
    ciamClient?.liveMode,
    project?.ciamProdAppId,
    project?.ciamUatAppId,
  ]);

  return (
    <Card className={style['ClientTile']} key={client.id}>
      <Flex.Container justify="space-between">
        <div>
          <P>{client.name ?? 'Client ID'}</P>
          <Span className={style['Subtext']}>{client.id}</Span>
        </div>
        {!ciamClientsLoading && (!isScopesEqual || !isCiamApplicationSame) && (
          <Tooltip
            targetElement={
              <span>
                <WarnIcon title="" />
              </span>
            }
          >
            <Div bottom="small" style={{ textAlign: 'left' }} top="small">
              {!isScopesEqual && (
                <P>
                  Client&apos;s scopes do not match the available scopes for
                  this app.
                </P>
              )}
              {!isCiamApplicationSame && (
                <P>
                  Client&apos;s ciam application does not match the Project for
                  this app.
                </P>
              )}
            </Div>
          </Tooltip>
        )}
      </Flex.Container>
      <Hr />
      <Div className={style['ClientTileContent']}>
        <Drawer
          triggerAttributes={{
            text: 'Edit',
            icon: EditIcon,
            variant: 'tertiary',
            icon_position: 'left',
          }}
        >
          <Drawer.Header>
            <H2>{client.name}</H2>
            <P>{client.id}</P>
            <P>{client.description}</P>
          </Drawer.Header>
          <Drawer.Body>
            {ciamClientsLoading ? (
              <LoadingModal />
            ) : (
              <ClientEdit
                ciamClient={ciamClient}
                ciamClientsLoading={ciamClientsLoading}
                client={client}
                currentApiAndScopeEntitlements={currentApiAndScopeEntitlements}
                hasPrivilageToEditClient={hasPrivilageToEditClient}
                isAdmin={isAdmin}
                noPrivilageError={noPrivilageError}
                onClientUpdated={() => onClientUpdated()}
                variant="EDIT"
              />
            )}
          </Drawer.Body>
        </Drawer>
        <div>
          <Drawer
            triggerAttributes={{
              text: '',
              icon: TrashIcon,
              variant: 'tertiary',
              icon_position: 'left',
            }}
          >
            <Drawer.Header>
              <H2>{client.name}</H2>
              <P>{client.id}</P>
              <P>{client.description}</P>
            </Drawer.Header>
            <Drawer.Body>
              {ciamClientsLoading ? (
                <LoadingModal />
              ) : (
                <ClientEdit
                  ciamClient={ciamClient}
                  ciamClientsLoading={ciamClientsLoading}
                  client={client}
                  currentApiAndScopeEntitlements={
                    currentApiAndScopeEntitlements
                  }
                  hasPrivilageToEditClient={hasPrivilageToEditClient}
                  isAdmin={isAdmin}
                  noPrivilageError={noPrivilageError}
                  onDeleteClicked={() => onDeleteClicked()}
                  variant="DELETE"
                />
              )}
            </Drawer.Body>
          </Drawer>
          {isAdmin && onDeleteFromPortalClicked && (
            <Dialog
              confirmText="Delete from portal"
              confirmType="warning"
              declineText="Cancel"
              description="This action will not impact client in ciam."
              icon={TrashIcon}
              onConfirm={({ close }) => {
                close();
                onDeleteFromPortalClicked();
              }}
              title="Delete from portal"
              triggerAttributes={{
                size: 'small',
                icon: TrashIcon,
                variant: 'signal',
              }}
              variant="confirmation"
            />
          )}
        </div>
      </Div>
    </Card>
  );
}
